export function startHighlightNewTransmissions() {
  const lastVisitTime = localStorage.getItem('lastVisitTime');
  const currentTime = new Date().toISOString();

  document.querySelectorAll('.box-dark').forEach((transmission) => {
    const transmissionTime = transmission.dataset.timestamp;
    if (lastVisitTime && new Date(transmissionTime) > new Date(lastVisitTime)) {
      transmission.classList.add('box-highlight');
      transmission.addEventListener('click', () => {
        transmission.classList.remove('box-highlight');
      });
    }
  });

  localStorage.setItem('lastVisitTime', currentTime);
}
